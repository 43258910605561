import styles from '../../../modal.module.less';
import { CrossIcon } from 'lib/Icons';
import React from 'react';
import SidebarMobileButtons from '../../../../SidebarMobileButtons/SidebarMobileButtons';
import { Button } from 'antd';
import useGetBreakpoints from 'lib/hooks/useGetBreakpoints/useGetBreakpoints';

const ModalHeader = (props) => {
  const { gameName, onClose, setSidebarOpen, setActiveTab } = props;
  const { checkBreakpoint } = useGetBreakpoints();
  const isLargeScreen = checkBreakpoint(['xl', 'xxl']);

  return (
    <div className={styles.headerTop}>
      <div className={styles.gameLaunchHeaderLeftSide}>
        <div className={styles.gameLaunchHeaderTitle}>
          {gameName}
        </div>
      </div>

      <div className={styles.gameLaunchHeaderRightSide}>
        {!isLargeScreen && (
          <SidebarMobileButtons
            setSidebarOpen={setSidebarOpen}
            setActiveTab={setActiveTab}
          />
        )}
        <Button
          onClick={onClose}
          className={styles.gameLaunchHeaderRightItem}
          icon={<CrossIcon />}
          color='default'
          type='text'
        />
      </div>
    </div>
  );
};

export default ModalHeader;