import './App.less';
import React from 'react';
import LayoutWrapper from 'components/Layout';
import { AuthProvider } from 'context/AuthContext';

const App = () => {
  return (
    <AuthProvider>
      <LayoutWrapper />
    </AuthProvider>
  );
};

export default App;