import React from 'react';
import styles from './styles.module.less';
import { LeaderboardIcon, MobileGameIcon } from 'lib/Icons';
import { Button } from 'antd';
import { LEADERBOARD_TAB, OTHER_GAMES_TAB } from '../Sidebar/components/Header/Header';

const SidebarMobileButtons = ({ setSidebarOpen, setActiveTab }) => {
  return (
    <div className={styles.buttonsContainer}>
      <div className={styles.content}>
        <div className={styles.icon}>
          <Button
            onClick={() => {
              setSidebarOpen(true);
              setActiveTab(LEADERBOARD_TAB);
            }}
            icon={<LeaderboardIcon />}
            color='default'
            type='text'
          />
        </div>
        <div className={styles.icon}>
          <Button
            onClick={() => {
              setSidebarOpen(true);
              setActiveTab(OTHER_GAMES_TAB);
            }}
            icon={<MobileGameIcon />}
            color='default'
            type='text'
          />
        </div>
      </div>
    </div>
  );
};

export default SidebarMobileButtons;