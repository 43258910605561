import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { AuthContext } from 'context/AuthContext';
import { mobileAndTabletCheck } from 'utils/helper';
import { Card, Form } from 'antd';
import GameItem from './GameItem';
import styles from '../../styles.module.less';
import SearchNoResult from './SearchNoResult';
import SearchBox from './SearchBox';
import { useGames } from '../../hooks/useGames';
import { useGetGamesCount } from '../../hooks/useGetGamesCount';
import Button from 'components/Button/Button';

const DESKTOP_TYPE = 1;
const MOBILE_TYPE = 2;
let timer;
const { Meta } = Card;
export default (params) => {
  const {
    tournament, periodId, gameIdParam, playTypeParam, testEnv,
    prizes, myScore, leaderboardData, currency,
  } = params;

  const [form] = Form.useForm();
  const [state] = useContext(AuthContext);
  const count = useGetGamesCount();

  const {
    totalCount, games, handleLoadMore,
    getGamesData, isLoading, pageNumber, setPageNumber,
  } = useGames({ periodId, count, form, testEnv });

  const visibleLoadMoreButton = Math.ceil((totalCount / 12)) !== pageNumber;

  const deviceType = useMemo(() => {
    if (state.deviceType) return Number(state.deviceType);
    return mobileAndTabletCheck() ? MOBILE_TYPE : DESKTOP_TYPE;
  }, [state.deviceType]);

  const isNoResult = form.getFieldValue('search')?.length > 0 && totalCount === 0;

  const onFilter = ({ search }) => {
    if (testEnv) return true;
    clearTimeout(timer);
    timer = setTimeout(() => {
      setPageNumber(1);
      getGamesData(1, search);
    }, 300);
  };

  return (
    <div className={styles.tournamentSingleGamesWrapper}>
      <div className={styles.tournamentSingleGamesHeader}>
        <div className={styles.tournamentSingleGamesTitle}>{state.translations?.tournamentGames} ({totalCount})</div>

        <div className={styles.tournamentSingleGamesSearchWrapper}>
          <Form
            preserve={false}
            form={form}
            onValuesChange={onFilter}
          >
            <SearchBox
              getGames={getGamesData}
              setPageNumber={setPageNumber}
            />
          </Form>
        </div>
      </div>
      <div
        className={classNames(styles.tournamentSingleGames, 'row-gutter-16 row-gutter-lg-12 row-gutter-md-12 row-gutter-sm-8')}
      >
        {isNoResult ? (
          <SearchNoResult text={state.translations?.noResults} />
        ) : games.map((game) => (
          <GameItem
            key={game.id}
            deviceType={deviceType}
            game={game}
            periodId={periodId}
            tournament={tournament}
            testEnv={testEnv}
            gameIdParam={gameIdParam}
            playTypeParam={playTypeParam}
            prizes={prizes}
            myScore={myScore}
            leaderboardData={leaderboardData}
            currency={currency}
          />
        ))}
      </div>

      {!isNoResult && games.length !== totalCount && (
        <div className={styles.tournamentSingleGamesLoad}>
          {
            visibleLoadMoreButton && (
              <Button
                type='button'
                size='large'
                onClick={handleLoadMore}
                disabled={isLoading}
              >
                {state.translations?.loadMoreGames}
              </Button>
            )}
        </div>
      )}
    </div>
  );
}
