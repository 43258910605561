import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { setLunchModalVisible } from '../../context/reducerActions';

const useIframeHeight = () => {
  const [{ isLunchGame }, dispatch] = useContext(AuthContext);

  useEffect(() => {
    const handlePopState = () => {
      setLunchModalVisible(dispatch, false);
    };

    window.addEventListener('popstate', handlePopState);

    const sendHeight = () => {
      const bodyHeight = document.body.scrollHeight;
      const value = isLunchGame ? 'calc(100vh - 104px)' : `${bodyHeight}px`;
      window.parent.postMessage({ data: { type: 'changeHeight', value }, dirChild: true }, '*');
    };

    sendHeight();

    const observer = new ResizeObserver(() => {
      sendHeight();
    });

    observer.observe(document.body);

    return () => {
      observer.disconnect();
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isLunchGame]);
};

export default useIframeHeight;
