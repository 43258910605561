import React, { useContext } from 'react';
import classNames from 'classnames';
import { AuthContext } from 'context/AuthContext';
import { getPlayerStatus, PLAYER_STATUS_JOINED, WIDGET_STATUS_LIVE, WIDGET_STATUS_UPCOMING } from 'enums/tournament';
import Button from '../Button/Button';
import styles from './styles.module.less';

const JoinButton = ({ tournament, extraClass = '', widgetStatus, handleJoin, isLoading, isTabletSm }) => {
  const [state] = useContext(AuthContext);
  const hasJoinButton = [WIDGET_STATUS_LIVE, WIDGET_STATUS_UPCOMING].includes(widgetStatus);
  const playerStatus = Object.keys(tournament).length > 0 && tournament.joinedStatus;

  return playerStatus !== null && hasJoinButton && (
    <>
      <Button
        block
        type='button'
        onClick={handleJoin}
        loading={isLoading}
        disabled={playerStatus === PLAYER_STATUS_JOINED}
        className={classNames(styles.joinBtn, styles[`joinBtn${playerStatus}`], extraClass, isTabletSm && styles.joinBtnSm)}
      >
        {getPlayerStatus(playerStatus, state)}
      </Button>
    </>
  );
};

export default JoinButton;
