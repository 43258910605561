import React, { useContext, useEffect, useMemo, useState } from 'react';
import useTournamentAPI from 'apis/useTournamentAPI';
import { msgToParent } from 'services/processPostMessage/ProcessPostMessage';
import styles from '../../modal.module.less';
import { mobileAndTabletCheck } from 'utils/helper';
import { AuthContext } from 'context/AuthContext';
import { PlayerInfo } from './components/PlayerInfo';
import ModalHeader  from './components/ModalHeader';
import { PLAYER_STATUS_JOINED, WIDGET_STATUS_CANCELED, WIDGET_STATUS_ENDED } from 'enums/tournament';
import { UnJoinedIcon } from 'lib/Icons';

const DESKTOP_TYPE = 1;
const MOBILE_TYPE = 2;
const PLAY_TYPE_DEMO = 'demo';

export default (props) => {
  const {
    onClose, handleGameLaunch, modalData, status,
    setSidebarOpen, joinedStatus, myScore, setActiveTab,
  } = props;

  const { mode, gameName, gameProviderId } = modalData;

  const hasUnJoinedText = (status !== WIDGET_STATUS_ENDED && status !== WIDGET_STATUS_CANCELED);
  const [state] = useContext(AuthContext);
  const [launchUrl, setLaunchUrl] = useState(null);
  const { getGameLaunchUrl } = useTournamentAPI();

  const deviceType = useMemo(() => {
    if (state.deviceType) return Number(state.deviceType);
    return mobileAndTabletCheck() ? MOBILE_TYPE : DESKTOP_TYPE;
  }, [state.deviceType]);

  const getAndSetGameLaunchUrl = async (mode, gameId, gameName, gameProviderId) => {
    if (navigator.userAgent.toLocaleLowerCase().includes('mobnative')) {
      msgToParent({
        type: 'gameLaunch',
        value: {
          name: gameName,
          gameProviderId,
          gameId,
          isDemo: mode === PLAY_TYPE_DEMO,
        },
      });
    }
    
    const gameLaunchUrl = await getGameLaunchUrl(deviceType, mode, gameId);
    if (gameLaunchUrl?.hasError === false) {
      setLaunchUrl(gameLaunchUrl.data);
    }
  };

  useEffect(() => {
    const setGameListener = (e) => {
      const { mode, ...rest } = e.detail;
      handleGameLaunch({ mode, gameData: { ...rest } });
    };

    window.addEventListener('fromSidebar', setGameListener);
    return () => window.removeEventListener('fromSidebar', setGameListener);
  }, []);

  useEffect(() => {
    getAndSetGameLaunchUrl(mode, modalData.gameId, gameName, gameProviderId);
  }, [modalData]);

  useEffect(() => {
    const handleBackToHome = (event) => {
      const { type } = event.data;
      if (type === 'rgs-backToHome') {
        onClose();
      }
    };
    window.addEventListener('message', handleBackToHome);
    msgToParent({ type: 'scrollto', value: 0 });
    return () => {
      window.removeEventListener('message', handleBackToHome);
    };
  }, []);

  return (
    <div className={styles.gameLaunchWrapper}>
      <div className={styles.gameLaunchBox}>

        <div className={styles.gameLaunchHeader}>
          <ModalHeader
            gameName={gameName}
            onClose={onClose}
            setSidebarOpen={setSidebarOpen}
            setActiveTab={setActiveTab}
          />
          <div className={styles.line}></div>
          <div className={styles.headerBottom}>
            {joinedStatus === PLAYER_STATUS_JOINED ? (
              <PlayerInfo myScore={myScore} />
            ) : (
              hasUnJoinedText &&
              <div className={styles.unJoinedText}>
                <UnJoinedIcon />
                <span>{state.translations?.notParticipating || "You're not participating in this tournament."}</span>
              </div>
            )
            }
          </div>
        </div>
        <div className={styles.gameLaunchBody}>
          {launchUrl !== null && (
            <iframe
              src={launchUrl}
              allowFullScreen
            />
          )}
        </div>

      </div>
    </div>
  );
};