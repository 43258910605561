import React, { useContext } from 'react';
import { AuthContext } from 'context/AuthContext';
import styles from '../styles.module.less';
import DummyImg from 'assets/img/dummy-img.png';
import classNames from 'classnames';
import Button from 'components/Button/Button';
import { createCustomEvent } from '../../../utils/helper';
import { PlayModeIcon } from 'lib/Icons';
import { PLAY_TYPE_DEMO, PLAY_TYPE_REAL } from 'enums/tournament';

export const GameCard = ({ game, setSidebarOpen }) => {
  const [state] = useContext(AuthContext);
  return (
    <>
      <div className={styles.gameImg}>
        <img src={game.imageUrl ?? DummyImg} alt='img' />
        <div className={styles.actionsWrapper}>
          <div className={classNames(styles.gameActionsBtns, !game.hasDemo ? styles.gameActionsBtnsOnlyPlay : '')}>
            {
              game.hasDemo && (
                <Button
                  type='button'
                  className='demoBtn'
                  onClick={() => {
                    setSidebarOpen(false);
                    createCustomEvent({ game, type: PLAY_TYPE_DEMO });
                  }}
                >
                  <span>{state.translations?.demo}</span>
                </Button>
              )
            }
            <Button
              type='button'
              className={styles.playBtn}
              onClick={() => {
                setSidebarOpen(false);
                createCustomEvent({ game, type: PLAY_TYPE_REAL });
              }}
            >
                <span>
                <PlayModeIcon />
                  {state.translations?.play}
                </span>
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.gameContent}>
        <div className={styles.gameName}>
          {game.name}
        </div>
        <div className={styles.providerName}>
          {game.providerName}
        </div>
      </div>
    </>
  );
};