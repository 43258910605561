import {
  SET_AUTH_TOKEN,
  SET_LAYOUT_TYPE,
  SET_TRANSLATIONS,
  SET_LANGUAGE_ID,
  SET_TEST_ENV,
  SET_DEVICE_TYPE, SET_TOURNAMENT, SET_TOURNAMENT_STATUS, SET_LUNCH_MODAL_VISIBLE,
} from './reducerConstant';

export const dispatchSetLayoutType = (dispatch, value) => (
  dispatch({
    type: SET_LAYOUT_TYPE,
    payload: { value },
  })
);

export const dispatchSetTestEnv = (dispatch, value) => (
  dispatch({
    type: SET_TEST_ENV,
    payload: { value },
  })
);

export const dispatchSetAuthToken = (dispatch, value) => (
  dispatch({
    type: SET_AUTH_TOKEN,
    payload: { value },
  })
);

export const dispatchSetTranslations = (dispatch, value) => (
  dispatch({
    type: SET_TRANSLATIONS,
    payload: { value },
  })
);

export const dispatchSetLanguageId = (dispatch, value) => (
  dispatch({
    type: SET_LANGUAGE_ID,
    payload: { value },
  })
);

export const dispatchDeviceType = (dispatch, value) => (
  dispatch({
    type: SET_DEVICE_TYPE,
    payload: { value },
  })
);

export const setTournamentSingle = (dispatch, value) => {
  dispatch({
    type: SET_TOURNAMENT,
    payload: { value },
  });
};

export const setTournamentJoinedStatus = (dispatch, value) => {
  dispatch({
    type: SET_TOURNAMENT_STATUS,
    payload: { value },
  });
};

export const setLunchModalVisible = (dispatch, value) => {
  dispatch({
    type: SET_LUNCH_MODAL_VISIBLE,
    payload: { value },
  });
};