import { useEffect, useState, useContext } from 'react';
import useTournamentAPI from '../../../apis/useTournamentAPI';
import { WIDGET_STATUS_LIVE } from '../../../enums/tournament';
import { AuthContext } from '../../../context/AuthContext';
import { setTournamentSingle } from '../../../context/reducerActions';

export default (tournamentId, periodIdAndStatus, connect, disconnect, setPeriodIdAndStatus, paramPeriodId) => {
  const { getTournament, getLeaderboard } = useTournamentAPI();

  const [isLoading, setLoading] = useState(true);
  const [state, dispatch] = useContext(AuthContext);
  const [leaderboard, setLeaderboard] = useState([]);
  const [myScore, setMyScore] = useState({});
  const [prizes, setPrizes] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [socketData, setSocketData] = useState(null);
  const { tournament,translations } = state;

  const getLeaderboardData = async (_tournamentId, _periodId) => {
    const leaderboardData = await getLeaderboard(_tournamentId, _periodId);
    if (leaderboardData?.hasError === false) {
      leaderboardData?.data.prizes.sort((a, b) => a.place - b.place)
      setPrizes(leaderboardData?.data.prizes);
      setLeaderboard(leaderboardData?.data.leaderboard);
      setMyScore(leaderboardData?.data.myScore);
    }
  };

  useEffect(() => {
    async function start() {
      setLoading(true);
      const tournamentData = await getTournament(tournamentId, paramPeriodId);
      if (tournamentData?.hasError === false) {
        setTournamentSingle(dispatch, {
          ...tournamentData?.data.tournament,
          playerLoggedIn: tournamentData?.data.playerLoggedIn,
        });
        if (tournamentData?.data?.periods.length === 1) {
          tournamentData.data.periods[0].periodName = translations?.general ?? 'General';
        }

        if (tournamentData?.data?.tournament?.comulative) {

          const { length } = tournamentData.data.periods;
          const firstElem = tournamentData.data.periods[0];
          const lastElem = tournamentData.data.periods[length - 1];

          tournamentData.data.periods.push({
            startDate: firstElem?.startDate,
            showDate: firstElem?.showDate,
            endDate: lastElem?.endDate,
            hideDate: lastElem?.hideDate,
            periodName: state.translations?.cumulative,
            periodId: null,
            status: tournamentData.data.tournament.status,
          });
        }

        setPeriods(tournamentData?.data.periods);
        const { periodId: id, status } = tournamentData?.data?.tournament;
        setPeriodIdAndStatus({ id, status });
      }

      setLoading(false);
    }

    start();

    return () => {
      setTournamentSingle(dispatch, {});
    };
  }, []);

  useEffect(() => {
    if (periodIdAndStatus !== null) {
      getLeaderboardData(tournamentId, periodIdAndStatus?.id);

      if (periodIdAndStatus?.status === WIDGET_STATUS_LIVE) {
        connect((wsData) => {
          setSocketData(wsData);
        }, periodIdAndStatus?.id);
      }

    }

    return () => {
      disconnect();
      setSocketData(null);
    };
  }, [periodIdAndStatus]);

  useEffect(() => {
    if (socketData !== null) {
      if (socketData.type === 'leaderboards') {
        const currentPlayerIndex = socketData.data.findIndex(item => item.id === myScore?.id);
        if (currentPlayerIndex !== -1) {
          setMyScore({ ...socketData.data[currentPlayerIndex], place: currentPlayerIndex + 1 });
        }
        setLeaderboard(socketData.data);

      } else if (socketData.type === 'player') {
        const oldPlace = myScore?.place - 1;

        setLeaderboard((prevLeaderboard) => {
          const newPlace = prevLeaderboard.findIndex(item => item.id === myScore?.id);

          if (newPlace !== -1 && oldPlace !== newPlace) {
            if (prevLeaderboard[oldPlace]) {
              prevLeaderboard.splice(oldPlace, 1);
              prevLeaderboard.splice(newPlace, 0, socketData.data);

            } else if (prevLeaderboard[newPlace]) {
              prevLeaderboard.splice(newPlace, 0, socketData.data);

              if (prevLeaderboard.length > tournament.leaderboardCount) {
                prevLeaderboard.pop();
              }
            }

            return [...prevLeaderboard];
          }

          return prevLeaderboard;
        });

        setMyScore({ ...socketData.data });
      }
    }
  }, [socketData]);


  return {
    isLoading,
    tournament,
    leaderboard: JSON.parse(JSON.stringify(leaderboard)),
    myScore,
    prizes,
    periods,
    getLeaderboardData,
  };
}