import React, { useContext } from 'react';
import { useGames } from '../../../../hooks/useGames';
import styles from './styles.module.less';
import Button from 'components/Button/Button';
import { AuthContext } from 'context/AuthContext';
import { SidebarGameItem } from './components/SidebarGameItem';

const count = 50;

const OtherGames = (props) => {
  const { periodId, testEnv = false, form, setSidebarOpen } = props;
  const [state] = useContext(AuthContext);
  const {
    totalCount, games, pageNumber,
    handleLoadMore, isLoading,
  } = useGames({ periodId, count, form, testEnv });

  const visibleLoadMoreButton = Math.ceil((totalCount / 50)) !== pageNumber;

  return (
    <div className={styles.gamesWrapper}>
      <div className={styles.games}>
        {games.map(game => {
          return (
            <React.Fragment key={game.id}>
              <SidebarGameItem
                game={game}
                setSidebarOpen={setSidebarOpen}
              />
            </React.Fragment>
          );
        })}
      </div>
      {games.length !== totalCount && (
        <div className={styles.tournamentSingleGamesLoad}>
          {
            visibleLoadMoreButton && (
              <Button
                type='button'
                size='large'
                onClick={handleLoadMore}
                block
              >
                {state.translations?.loadMoreGames}
              </Button>
            )
          }
        </div>
      )}
    </div>
  );
};

export default OtherGames;