import React, { useContext } from 'react';
import styles from './styles.module.less';
import { GameIcon, LeaderboardIcon } from 'lib/Icons';
import classNames from 'classnames';
import { CloseOutlined } from '@ant-design/icons';
import { AuthContext } from '../../../../../../context/AuthContext';

export const LEADERBOARD_TAB = 1;
export const OTHER_GAMES_TAB = 2;


const Header = (props) => {
  const { setActiveTab, activeTab, isLargeScreen, setSidebarOpen } = props;
  const [state] = useContext(AuthContext);
  const tabs = [
    { id: LEADERBOARD_TAB, name: state.translations?.leaderboard || 'Leaderboard', icon: <LeaderboardIcon /> },
    { id: OTHER_GAMES_TAB, name: state.translations?.otherGames || 'Other Games', icon: <GameIcon /> },
  ];

  return (
    <>
      {tabs.map(tab => {
        return (
          <div
            className={styles.headerItem}
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
          >
            <div className={styles.itemContent}>
              <div className={classNames(styles.icon, { [styles.iconActive]: activeTab === tab.id })}>
                {tab.icon}
              </div>
              <span className={classNames(styles.name, { [styles.nameActive]: activeTab === tab.id })}>
              {tab.name}
             </span>
              {activeTab === tab.id && (
                <div className={classNames({ [styles.active]: activeTab === tab.id })}></div>
              )}

            </div>

          </div>
        );
      })}
      {!isLargeScreen &&
        <div onClick={() => setSidebarOpen(false)} className={styles.closeIcon}><CloseOutlined /></div>}
    </>
  );
};

export default Header;