import React, { memo, useState } from 'react';
import { Spin } from 'antd';
import { WIDGET_STATUS_LIVE, WIDGET_STATUS_UPCOMING } from 'enums/tournament';
import DateTimeCounter from 'components/DateTimeCounter';
import styles from '../styles.module.less';
import JoinButton from 'components/JoinButton';
import { useGetTournamentRealStatus } from 'lib/hooks/useGetTournamentRealStatus';
import PopConfirm from 'components/PopConfirm/PopConfirm';
import { useVerifyAndJoin } from 'components/JoinButton/hooks';

const JoinButtonAndDateTimeCounterSingle = (props) => {

  const { isLoading, tournament, widgetStatus, setWidgetStatus } = props;
  const hasDateTimeCounter = (widgetStatus === WIDGET_STATUS_LIVE || widgetStatus === WIDGET_STATUS_UPCOMING);

  const [popconfirmProps, setPopconfirmProps] = useState({
    open: null,
    text: '',
    joinPopUp: false,
    hasCancelButton: true,
    onConfirm: () => {
    },
  });

  const { open, onConfirm, text, hasCancelButton } = popconfirmProps;

  const { loading, getTournamentRealStatus } = useGetTournamentRealStatus(tournament, setWidgetStatus);

  const onCancel = () => {
    setPopconfirmProps({ ...popconfirmProps, open: false });
  };

  const {
    handleJoin,
    isLoadingBtn,
  } = useVerifyAndJoin({ tournament, open, setPopconfirmProps, playerLoggedIn: tournament?.playerLoggedIn });

  return (
    <Spin spinning={loading}>
      {!isLoading && (
        hasDateTimeCounter && <DateTimeCounter
          tournament={tournament}
          widgetStatus={widgetStatus}
          setWidgetStatus={setWidgetStatus}
          getTournamentRealStatus={getTournamentRealStatus}
        />
      )}

      <div className={styles.tournamentSingleJoin}>
        <PopConfirm
          icon={null}
          open={open}
          onConfirm={onConfirm}
          onCancel={onCancel}
          text={text}
          hasCancelButton={hasCancelButton}
          setPopconfirmProps={setPopconfirmProps}
        >

          <JoinButton
            playerLoggedIn={tournament.playerLoggedIn}
            widgetStatus={widgetStatus}
            tournament={tournament}
            handleJoin={handleJoin}
            extraClass={styles.tournamentJoinBtn}
            isLoading={isLoadingBtn}
          />

        </PopConfirm>
      </div>
    </Spin>
  );
};

export default memo(JoinButtonAndDateTimeCounterSingle);