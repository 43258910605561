import React, { createContext, useReducer } from 'react';
import reducer from './reducer';
import { LAYOUT_TYPE_LIST } from '../utils/common';
import { translations } from '../enums/mockData';

const AuthContext = createContext({});

const tmpObj = {};
translations.data.forEach((translation) => {
  tmpObj[translation.translationId] = translation.text;
});

const initialState = {
  layoutType: LAYOUT_TYPE_LIST,
  translations: tmpObj,
  testEnv: null,
  tournament: {},
  authToken: null,
  languageId: 'en',
  deviceType: null,
  isLunchGame: false
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AuthContext.Provider value={[state, dispatch]}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
