import Sticky from 'react-stickynode';
import classNames from 'classnames';
import React, { useContext } from 'react';
import styles from '../styles.module.less';
import { WIDGET_STATUS_LIVE } from 'enums/tournament';
import { getMonthName } from '../../../utils/helper';
import { AuthContext } from 'context/AuthContext';

export default ({ tournament, periods, setPeriodIdAndStatus, periodId, testEnv }) => {
  const [state] = useContext(AuthContext);

  const handleClick = (period) => {
    if (!testEnv) {
      if (period.periodId !== periodId) {
        if (period.periodId === null) {
          setPeriodIdAndStatus({
            id: period?.periodId,
            status: period?.status,
            cumulative: true,
          });
        } else {
          setPeriodIdAndStatus({
            id: period?.periodId,
            status: period?.status,
          });
        }
      }
    }
  };

  const getDate = (period) => {
    return `${getMonthName(period?.startDate?.substring(0, 10), state?.translations)} ${period.startDate?.substring(11, 16)} - 
                ${getMonthName(period?.endDate?.substring(0, 10), state?.translations)} ${period.endDate?.substring(11, 16)}`;
  };

  return periods.length > 1 && (
    <Sticky enable innerZ={9}>
      <div className={styles.periodWrapper}>
        <div className={styles.periodList}>
          {periods.map((period) => {
            const hasGreenLine = period.status === WIDGET_STATUS_LIVE && period.periodId === tournament.periodId;

            return (
              <div
                key={period.periodId}
                className={classNames(styles.periodItem, {
                  [styles.periodActive]: period.periodId === periodId,
                  [styles.periodActiveLine]: hasGreenLine,
                })}
                onClick={() => handleClick(period)}
              >
                <p className={styles.periodItemText}> {period?.periodName}</p>
                <p className={styles.periodItemText}>
                  {getDate(period)}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </Sticky>
  );
}