import React, { useContext } from 'react';
import styles from './styles.module.less';
import { LeaderboardTable } from '../../../leaderboards/LeaderboardTable';
import { AuthContext } from 'context/AuthContext';

const LeaderBoard = (props) => {
  const {
    prizes,
    myScore,
    leaderboardData,
    currency,
  } = props;
  const [state] = useContext(AuthContext);

  return (
    <div className={styles.leaderboardWrapper}>
      <div className={styles.tournamentSingleLeaderboardPrizesItem}>
        <div className={styles.tournamentSingleLeaderboardPrizesItemTable}>
          <LeaderboardTable
            state={state}
            leaderboardData={leaderboardData}
            prizes={prizes}
            myScore={myScore}
            currency={currency}
          />
        </div>
      </div>
    </div>
  );

};

export default LeaderBoard;