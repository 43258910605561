import styles from '../styles.module.less';
import React, { useRef } from 'react';
import { GameCard } from './GameCard';

export const SidebarGameItem = ({ game, setSidebarOpen }) => {
  const imageRef = useRef(null);

  const handleTouchStart = (e) => {
    e.stopPropagation();
    imageRef.current.focus();
  };

  return (
    <div
      className={styles.gameItem}
      ref={imageRef}
      onTouchStart={handleTouchStart}
    >
      <GameCard
        game={game}
        setSidebarOpen={setSidebarOpen}
      />
    </div>
  );
};