import FlipMove from 'react-flip-move';
import React, { useContext } from 'react';
import { formatNumber } from 'utils/helper';
import { AuthContext } from '../../../../context/AuthContext';

const onStartAnimation = () => (childElements, domNodes) => {
  for (let i = 0; i < domNodes.length; i++) {
    domNodes[i].classList.add('current');
  }
};
const onFinishAnimation = () => () => {
  const currents = document.querySelectorAll('.current');
  setTimeout(() => {
    currents.forEach(item => {
      item.classList.remove('current');
    });
  }, 1000);
};


const LeaderBoardAnimation = ({ leaderboardData, myScore, prizes }) => {
  const [state] = useContext(AuthContext);
  return (
    <FlipMove
      enterAnimation={false}
      leaveAnimation={false}
      appearAnimation='fade'
      onStartAll={onStartAnimation()}
      onFinishAll={onFinishAnimation()}
      duration={1500}
      typeName='tbody'
    >
      {leaderboardData.map((player, index) => {
        let me = '';
        const prize = prizes[index];
        if (player?.isMyPlace) {
          me = state.translations?.me ? `(${state.translations?.me})` : '(me)';
        }

        const prizeDescription = prize !== undefined ? formatNumber(player.prize) : '-';
        return (
          <tr key={player.id}>
            <td className='td_1'>{player.place}</td>
            <td className='td_2'>{player?.userName} {me}</td>
            <td className='td_3'>{formatNumber(player?.score)}</td>
            <td className='td_4'>{prizeDescription}</td>
          </tr>
        );
      })}
    </FlipMove>
  );
};

export default LeaderBoardAnimation;
