import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useTournamentAPI from '../../../apis/useTournamentAPI';

export const useGames = ({ periodId, count, form, testEnv }) => {
  const { getGames } = useTournamentAPI();
  const [pageNumber, setPageNumber] = useState(1);

  const params = useParams();
  const tournamentId = params?.id;

  const [isLoading, setLoading] = useState(false);
  const [games, setGames] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const getGamesData = async (pageNumberTmp, searchText = '') => {
    setLoading(true);

    if (periodId) {
      const gamesData = await getGames({
        tournamentId,
        periodId,
        pageSize: count,
        pageNumber: pageNumberTmp,
        filterGameName: searchText,
      });

      if (gamesData?.hasError === false) {
        if (pageNumberTmp === 1) {
          setGames(gamesData?.data.games);
        } else {
          setGames([...games, ...gamesData?.data.games]);
        }
        setTotalCount(gamesData?.data.totalCount);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    form.resetFields(['search']);
    setPageNumber(1);
    getGamesData(1);
  }, [periodId]);

  const handleLoadMore = () => {
    if (testEnv) return true;
    const searchedName = form.getFieldValue('search');
    const newPageId = pageNumber + 1;
    setPageNumber(newPageId);
    getGamesData(newPageId, searchedName);
  };

  return { totalCount, isLoading, games, handleLoadMore, getGamesData, pageNumber, setPageNumber };
};