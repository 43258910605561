import styles from '../../styles.module.less';
import classNames from 'classnames';
import DummyImg from 'assets/img/dummy-img.png';
import Button from 'components/Button/Button';
import { PlayModeIcon } from 'lib/Icons';
import React, { useContext, useRef } from 'react';
import { AuthContext } from 'context/AuthContext';
import { PLAY_TYPE_DEMO, PLAY_TYPE_REAL } from 'enums/tournament';

export const GameCard = (props) => {
  const { isDesktop, game, handleGameLaunch } = props;
  const [state] = useContext(AuthContext);
  const imageRef = useRef(null);

  const handleTouchStart = (e) => {
    e.preventDefault();
    imageRef.current.focus();
  };

  const handleOnClickGameLaunch = (mode) => {
    const gameData = { gameName: game.name, gameId: game.id, gameProviderId: game.providerId };
    handleGameLaunch({ mode, gameData });
  };

  return (
    <div
      className={styles.tournamentSingleGame}>
      <div
        className={classNames(styles.tournamentSingleGameImage, isDesktop ? '' : styles.tournamentSingleGameImageMobile)}
        ref={imageRef}
        onTouchStart={handleTouchStart}
      >
        <img
          src={game.imageUrl ?? DummyImg}
          alt={game.name}
        />
        <div className={styles.tournamentSingleGameActions}>
          <div
            className={classNames(styles.tournamentSingleGameActionsBtn, !game.hasDemo ? styles.tournamentSingleGameActionsBtnOnlyPlay : '')}>
            <Button
              type='button'
              className='playBtn'
              onClick={() => {
                handleOnClickGameLaunch(PLAY_TYPE_REAL);
              }}
            >
              <PlayModeIcon />
              <span>{state.translations?.play}</span>
            </Button>
            {
              game.hasDemo && (
                <Button
                  type='button'
                  className='demoBtn'
                  onClick={() => {
                    handleOnClickGameLaunch(PLAY_TYPE_DEMO);
                  }}
                >
                  {state.translations?.demo}
                </Button>
              )
            }
          </div>
        </div>
      </div>
      <div className={styles.tournamentSingleGameTitle}>{game.name}</div>
      <div className={styles.tournamentSingleGameSubTitle}>{game.providerName}</div>
    </div>
  );
};