import {
  SET_LAYOUT_TYPE,
  SET_AUTH_TOKEN,
  SET_TRANSLATIONS,
  SET_LANGUAGE_ID,
  SET_TEST_ENV,
  SET_DEVICE_TYPE,
  SET_TOURNAMENT, SET_TOURNAMENT_STATUS, SET_LUNCH_MODAL_VISIBLE,
} from './reducerConstant';

export default (state, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_LAYOUT_TYPE:
      return {
        ...state,
        layoutType: payload.value,
      };
    case SET_AUTH_TOKEN:
      return {
        ...state,
        authToken: payload.value,
      };
    case SET_TRANSLATIONS:
      return {
        ...state,
        translations: payload.value,
      };
    case SET_LANGUAGE_ID:
      return {
        ...state,
        languageId: payload.value,
      };
    case SET_TEST_ENV:
      return {
        ...state,
        testEnv: payload.value,
      };
    case SET_DEVICE_TYPE:
      return {
        ...state,
        deviceType: payload.value,
      };
    case SET_TOURNAMENT :
      return {
        ...state,
        tournament: payload.value,
      };
    case SET_TOURNAMENT_STATUS :
      // eslint-disable-next-line no-param-reassign
      state.tournament.joinedStatus = payload.value;
      return {
        ...state,
        tournament: {
          ...state.tournament,
        },
      };
    case SET_LUNCH_MODAL_VISIBLE:
      return {
        ...state,
        isLunchGame: payload.value
      }
    default:
      throw new Error();
  }
};
