import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { Row, Skeleton } from 'antd';
import useGetBreakpoints from 'lib/hooks/useGetBreakpoints/useGetBreakpoints';
import { LAYOUT_TYPE_GRID } from 'utils/common';
import { AuthContext } from 'context/AuthContext';
import { useParams } from 'react-router-dom';
import { labelsList } from 'enums/tournament';
import styles from '../styles.module.less';
import Item from './Item/Item';
import Filter from './Filter';
import NotTournaments from './NotTournaments/NotTournaments';

export default ({ tournaments, playerLoggedIn, loading }) => {
  const [state] = useContext(AuthContext);
  const params = useParams();
  const tabId = params?.tabId;

  const { breakpoint } = useGetBreakpoints();
  const isGrid = state.layoutType === LAYOUT_TYPE_GRID;

  const filteredTournaments = useMemo(() => {
    if (tabId) {
      return tournaments.filter((item) => item.status === labelsList[tabId]);
    }

    return tournaments;
  }, [tournaments, tabId]);

  let gutterStandard = {
    xxl: 24,
    xl: 24,
    lg: 16,
    md: 16,
    sm: 16,
  };

  if (isGrid) {
    gutterStandard = {
      xxl: 16,
      xl: 16,
      lg: 16,
      md: 16,
      sm: 16,
    };
  }

  return (
    <div className={styles.tournamentListWrapper}>
      <div className={styles.tournamentListSubWrapper}>
        <div className={styles.tournamentListTitle}>
          {state.translations?.tournaments}
        </div>

        <Filter
          tabId={tabId}
          translations={state.translations}
        />

        <Skeleton
          active
          title={null}
          loading={loading}
          className={styles.skeleton}
          paragraph={{ rows: 2 }}
        >
          {
            filteredTournaments.length === 0 ?
              <NotTournaments
                filterStatus={labelsList[tabId]}
              />
              :
              <Row
                gutter={[breakpoint === 'sm' ? 0 : gutterStandard[breakpoint], gutterStandard[breakpoint]]}
                className={classNames(isGrid ? styles.tournamentGrid : styles.tournamentList)}
              >
                {filteredTournaments.map((tournament) => (
                  <Item
                    playerLoggedIn={playerLoggedIn}
                    key={tournament?.periodId}
                    tournament={tournament}
                    isGrid={isGrid}
                  />
                ))}
              </Row>
          }
        </Skeleton>
      </div>
    </div>
  );
}
