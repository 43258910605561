import React from 'react';
import styles from './styles.module.less';
import Header, { LEADERBOARD_TAB, OTHER_GAMES_TAB } from './components/Header/Header';
import LeaderBoard from './components/LeaderBoard/LeaderBoard';
import OtherGames from './components/OtherGames/OtherGames';
import { Form } from 'antd';
import classNames from 'classnames';

const Sidebar = (props) => {
  const {
    periodId, prizes, myScore, leaderboardData,
    currency, setSidebarOpen, isLargeScreen, activeTab,
    setActiveTab,
  } = props;
  const [form] = Form.useForm();

  return (
    <div className={classNames(styles.sidebarWrapper)}>
      <div className={styles.sidebarContent}>
        <div className={styles.sidebarHeader}>
          <Header
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            isLargeScreen={isLargeScreen}
            setSidebarOpen={setSidebarOpen}
          />
        </div>
        {activeTab === LEADERBOARD_TAB && (
          <LeaderBoard
            prizes={prizes}
            myScore={myScore}
            leaderboardData={leaderboardData}
            currency={currency}
          />
        )}
        {activeTab === OTHER_GAMES_TAB && (
          <OtherGames
            periodId={periodId}
            form={form}
            setSidebarOpen={setSidebarOpen}
          />
        )}
      </div>
    </div>
  );
};

export default Sidebar;