export const createCustomEvent = ({ game, type }) => {
  const fromSidebar = new CustomEvent('fromSidebar', {
    detail: {
      fromSidebarOpen: true,
      mode: type,
      gameName: game.name,
      gameId: game.id,
      gameProviderId: game.providerId,
    },
  });
  window.dispatchEvent(fromSidebar);
};