import React, { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { msgToParent } from 'services/processPostMessage/ProcessPostMessage';
import { AuthContext } from 'context/AuthContext';
import { setLunchModalVisible } from 'context/reducerActions';
import { PLAY_TYPE_DEMO, PLAY_TYPE_REAL } from 'enums/tournament';
import useGetBreakpoints from 'lib/hooks/useGetBreakpoints';
import GameLaunchModal from './components/GameLaunchModal/GameLaunchModal';
import styles from '../../styles.module.less';
import Sidebar from '../Sidebar/Sidebar';
import { LEADERBOARD_TAB } from '../Sidebar/components/Header/Header';
import { GameCard } from './GameCard';

export default (props) => {
  const {
    game, tournament, deviceType, gameIdParam, playTypeParam,
    periodId, testEnv, prizes, myScore, leaderboardData, currency,
  } = props;
  const [_, dispatch] = useContext(AuthContext);
  const { joinedStatus, status } = tournament;
  const isDesktop = deviceType === 1;
  const { checkBreakpoint } = useGetBreakpoints();
  const isLargeScreen = checkBreakpoint(['xl', 'xxl']);

  const [activeTab, setActiveTab] = useState(LEADERBOARD_TAB);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [modalVisible, setVisibility] = useState(null);

  const openGame = (mode, gameData) => {
    setVisibility({ mode, ...gameData });
    const path = `/tournament/${tournament.id}/Periods/${tournament.periodId}/gameId/${game.id}/playType/${mode}`;
    setLunchModalVisible(dispatch, true);
    msgToParent({ type: 'currentURL', value: encodeURIComponent(path) });
  };

  const handleGameLaunch = ({ mode, gameData }) => {
    if (testEnv) return true;

    if ((mode === PLAY_TYPE_REAL && tournament?.playerLoggedIn) || mode === PLAY_TYPE_DEMO) {
      openGame(mode, gameData);
    } else {
      msgToParent({ type: 'openLogin' });
    }
  };

  const handleGameLaunchClose = () => {
    setVisibility(null);
    setLunchModalVisible(dispatch, false);
    const path = `/tournament/${tournament.id}/Periods/${tournament.periodId}`;
    msgToParent({ type: 'currentURL', value: encodeURIComponent(path) });
  };

  useEffect(() => {
    if (game.id == gameIdParam) {
      handleGameLaunch(playTypeParam);
    }
  }, []);

  return (
    <>
      <div
        className='col-16 col-xl-20 col-lg-25 col-md-33 col-sm-50 col-gutter-16 col-gutter-lg-12 col-gutter-md-12 col-gutter-sm-8'>
        <GameCard
          isDesktop={isDesktop}
          game={game}
          handleGameLaunch={handleGameLaunch}
        />
      </div>

      {modalVisible !== null && (
        createPortal(
          <div className={styles.modalAndSidebarWrapper}>
            <GameLaunchModal
              onClose={handleGameLaunchClose}
              handleGameLaunch={handleGameLaunch}
              modalData={modalVisible}
              status={status}
              setSidebarOpen={setSidebarOpen}
              joinedStatus={joinedStatus}
              myScore={myScore}
              setActiveTab={setActiveTab}
            />

            {(sidebarOpen || isLargeScreen) && (
              <Sidebar
                periodId={periodId}
                prizes={prizes}
                myScore={myScore}
                leaderboardData={leaderboardData}
                currency={currency}
                setSidebarOpen={setSidebarOpen}
                isLargeScreen={isLargeScreen}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            )}
          </div>,
          document.body,
        )
      )}
    </>
  );
}